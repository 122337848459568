<template>
  <div class="main h-100">
    <h2>FREQUENTLY ASKED QUESTIONS</h2>
    <p>1. How do I sign up?</p>
    <p>To get started, simply go to&nbsp;
      <a href="https://www.facebook.com/MathGuru-Homework-Solver-101379862480035">
        https://www.facebook.com/MathGuru-Homework-Solver-101379862480035
      </a>
    </p>
    <p>2. How will I receive my access?</p>
    <p>You&rsquo;ll receive a unique download link that will take you to the AppStore to download
      the app. Once the app is installed on your phone, you need to sign into the app using your
      email or Apple ID. You will have unlimited access to all the features within the app for the
      duration of your paid period.</p>
    <p>If you experience any difficulties with downloading or accessing the app, please contact us
      at&nbsp;https://www.facebook.com/MathGuru-Homework-Solver-101379862480035.</p>
    <p>3. I do not have access to my subscription any longer, what happened?</p>
    <p>As you purchase the plan on the website, you are given various subscription options. Once
      your chosen subscription expires, you lose access to the app. If you wish to continue using
      the app after your initial subscription expired, you may repurchase it within the app at any
      time.</p>
    <p>4. Can I cancel my subscription?</p>
    <p>You can find our subscription terms in Terms Of Service page. If for any reason you do not
      wish to use the app for longer you can unsubscribe at any time.</p>
    <p>5. Refund Policy</p>
    <p>We provide you with a 30 day money back guarantee so you can get a refund during that time if
      your expectations wasn&rsquo;t met.</p>
  </div>
</template>

<script>
export default {
  name: 'Faq',
};
</script>

<style scoped>

</style>
